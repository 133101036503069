.wrapper-home {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px;
}

@media (max-width: 768px) {
    .content{
        width: 100vw
    }
    .wrapper-home {
        justify-content: center;
    }
    .home-table td {
        border: 1px solid #656464;
    }
    .home-table th {
        border: 1px solid red;
    }
    .dashboard-list-button {
        border: none;
        border-radius: 5px;
        height: 30px;
        font-size: 10px;
        color: #fff;
    }
}

.result-img {
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
    background: #2e3193;
    border-radius: 12px;
}

.home-img:hover {
    opacity: 0.6;
}

.home-btn {
    display: inline-block; /* Đảm bảo nút hiển thị */
}


@media (max-width: 768px) {
    .home-img {
        width: 100%;
        max-height: 300px;
        height: 100%;
    }
}

.result-detail {
    position: absolute;
    top: 40%;
    width: 100%;
}

.result-styled-img {
    height: 30%;
    margin: 10px auto;
}

.home-text-link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 6px;
}

.home-text-time {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.result-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 768px) and (min-width: 70px) {

}

.home-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

@media (max-width: 768px) {
    .home-btn-group {
        width: 100%;
        flex-direction: column;
    }
}

.home-btn {
    width: 170px;
    height: 44px;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #fff;
    background: #000;
}

.home-btn:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .home-btn {
        width: 100%;
        height: 44px;
    }
}