canvas#canv {
    width: 100%;
    height: 50px;
}
.col-md-4 {
    box-shadow: 13px 13px 15px -9px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 13px 13px 15px -9px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 13px 13px 15px -9px rgba(0, 0, 0, 0.5);
}

.btnlogin {
    width: 100%;
    border-radius: 25px;
    background-color: #f7c06b;
    border: none;
    padding: 10px 0;
    font-size: 20px;
    font-weight: bold;
}

/* .inputgr{
    width: 100%;
} */
.MuiInput-underline:before {
    display: none;
}

.MuiInput-underline:after {
    display: none;
}

.wrapper-login {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    /* background-color: red !important; */
    background-image: "../../assets/img/Loginpage.png";
}

.container-login {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    padding: 45px;
    border-radius: 25px;
    background: #00104A;

    @media (max-width: 768px) {
        padding: 40px 20px;
    }
}

.form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
}

.buttonForget {
    color: #FFD700 !important;
    cursor: pointer; 
}

.inputCodeGroup {
    display: flex;
    justify-content: space-between;
}
.inputCode {
    border-radius: 10px;
}
.inputCode,
.inputShowCode {
    width: 175px;
    height: 50px;
    border: none;
    text-align: center;
    background-color: white;
    align-items: center;
}

.inputShowCode {
    font-weight: bold;
    align-items: center;
    display: flex;
    justify-content: center;
}

.input-account {
    width: 100%;
    margin: -8px 15px 0px 15px;
}

.title-Sign {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    color: #fff;
}
  
.button-SignIn {
    background-color: #5487f5 !important;
    width: 100% !important;
    height: 54px !important;
    border: none !important;
    border-radius: 16px !important; 
    font-size: 22px !important;
    font-weight: bold !important;
    color: white !important;
}
  
.background-css {
  background-position: center center;
  background-size: cover;
}

.iconUsername {
    width: 24px;
    height: 24px;
    margin-right: 100px;

  
}
.iconShowPass {
    width: 24px;
    height: 15px;
    margin-left: 300px;
    position: absolute;
    cursor: pointer;
}


@media (max-width: 768px) and (min-width: 70px) {
    .background-css {
      background-position: top; /* Đặt hình ảnh ở trên cùng và căn giữa theo chiều ngang */
      background-repeat: no-repeat;
      background-size: calc(1200px + 700px);
    }
    .inputCode,
    .inputShowCode {
        /*margin: 5px 15px;*/
        width: 155px;
        height: 50px;
        border: none;
        text-align: center;
    }
    .iconShowPass {
        width: 24px;
        height: 15px;
        margin-left: 80%;
        position: absolute;
        cursor: pointer;
    }
    


  }
  