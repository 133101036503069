
@media screen and (max-width: 768px) {
    .mempis-ket {
        max-width: 150px;
    }
    .th-h5, .td-h5 {
        padding: 8px 0 !important;
        font-size: 8px;
    }
    .bg-betType, .bg-tools {
        padding: 2px !important;
    }
}
.limit-tr01 {
    background-color: red;
    color: white;
}

.his-gameT {
    color: black;
}

.button-H5 {
    border-radius: 5px;
    border: none;
    margin: 0 15px;
    width: 160px;
    background-color: gray;
    height: 50px;
}

.h5-hs-col02 {
    width: 20%;
}

.h5-hs-col02-option {
    width: 95%;
    height: 60%;
    border: 2px solid black;
}

.table-h5 {
    border-collapse: collapse;
    width: 100%; 
    text-align: center; 
}

.th-h5, .td-h5 {
    border: 1px solid black; 
    padding: 8px;
    text-align: center; 
}

.fontWeight1000 {
    font-weight: 1000;
}


/* DASHBOARD */
.dashboard-bg {
    background-color: white;   
}
.dashboard-title {
    font-size: 35px;
    text-align: center;
    padding: 20px;
}
.dashboard-list-tab {
    display: flex;
    justify-content: space-evenly;
}
.dashboard-item {
    border-radius: 5px;
    border: none;
    margin: 0 15px;
    width: 160px;
    background-color: gray;
    height: 50px;
}
.dashboard-IF-Enter {
    color: blue !important;
    font-size: 30px;
}
.dashboard-IF-status-on {
    color: green !important;
}
.dashboard-IF-status-off {
    color: red !important;
}
.dashboard-PD-row01,
.dashboard-PD-Col01 {
    background-color: red !important;
    color: white !important;
}

/* TOOLS */
.bg-betType,
.bg-tools {
    background-color: white;
    padding: 20px;
}
.tools-div-Title {
    display: flex;
    align-items: center;
}
.tools-Img-Back {
    width: 100px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
}
.tools-Title-Text {
    margin-left: 450px;
    font-size: 30px;
    width: 140px;
    margin-top: 20px;
    text-align: center;
}
.tools-div-02 {
    display: flex;
    justify-content: space-between;
    margin: 30px 30px;
}
.tools-div-02-Button-01 {
    background-color: #cc0002;
    height: 50px;
    width: 150px;
    border: none;
    border-radius: 5px;
    color: white;
}
.tools-div-02-Button-02 {
    background-color: #000;
    color: white;
    height: 40px;
    width: 100px;
    border: none;
    border-radius: 5px;
}
.tools-div-03 {
    display: flex;
    justify-content: space-between;
}
.tools-div-03-Select,
.tools-div-03-Input01,
.tools-div-03-Input02 {
    border: 1px solid black;
    border-radius: 5px;
}
.tools-div-03-Select {
    width: 100px;
    height: 35px;
    cursor: pointer;
}
.tools-div-03-Input01,
.tools-div-03-Input02 {
    margin-right: 10px;
    height: 40px;
}

/* HISTORY */
.history-Tile {
    display: flex;
    align-items: center;
}
.history-Img-Back {
    width: 100px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
}
.history-Title-Text {
    display: flex;
    margin: auto;
    font-size: 30px;
    width: 140px;
}
.history-list-button {
    border-radius: 5px;
    border: none;
    margin: 0 15px;
    width: 160px;
    background-color: gray;
    height: 50px;
}

/* INVOICE */
.invoice-table-last {
    background-color: red;
    color: white;
}

/* BetType */
.shio-col02,
.colokJitu-col03 {
    background-color: rgb(226, 219, 219) !important;
    border-radius: 25px;
}



.list-Item-BetType {
    background-color: gray;
    color: white;
    height: 80px;
    width: 220px;
    border: none;
    border-radius: 5px;
    margin: 10px 10px;
}

.Button-BetType-Submit {
    color: white;
    background-color: green;
    border: none;
    width: 15%;
    height: 52px;
    border-radius: 10px;
}

@media (max-width: 768px) and (min-width: 70px) {
    .bg {
        background-color: white;
        padding: 20px;
        width: 100% !important;
        max-width: 100% !important;
    }

    .table-h5 {
        width: 100% !important;
        max-width: 100% !important; 
    }

    .tools-Img-Back {
        width: 80px;
        height: 25px;
        margin-left: 5px !important;
        cursor: pointer;
    }

    .tools-Title-Text {
        margin-left: 5% !important;
        font-size: 25px;
    }

    .button-H5-H {
        border-radius: 5px;
        border: none;
        margin: 0 15px;
        width: 80% !important;
        background-color: gray;
        height: 50%;
        font-size: 12px;
    }

    .his-gameT {
        font-size: 12px;
    }
    .h5-hs-col02-option {
        width: 120% !important;
        height: 40%;
        border: 2px solid black;
        font-size: 10px !important;
    }

    .f12 {
        font-size: 12px !important;
    }

    .f10 {
        font-size: 10px !important;
    }



    /* TOOLS RESPONSIVE */
    .tools-div-02 {
        margin: 5px 5px !important;
    }

    .tools-div-02-Button-01 {
        height: 30px;
        width: 30%;
        font-size: 12px;
    }

    .tools-div-02-Button-02 {
        height: 10%;
        width: 60px;
        font-size: 15px;
    }

    .div-button-Betype {
        max-width: 100%;
        display: flex;
        justify-content: first baseline;
        align-items: center;
        flex-wrap: wrap;
    }

    .button-BetType {
        background-color: gray;
        color: white;
        height: 40px !important;
        width: 120px !important;
        border: none;
        border-radius: 5px;
        font-size: 12px;
        margin: 10px 10px;
    }

    .tools-div-03-Select {
        width: 70% !important;
        height: 50%;
    }

    .tools-div-input01 {
        width: 30%;
    }

    .tools-div-input02{
        margin-left: 5% !important;
        width: 30%;
    }

    .tools-div-03-Input01 {
        margin-right: 0px !important;
        height: 50%;
        width: 80% !important;
    }

    .tools-div-03-Input02 {
        margin-right: 0px !important;
        width: 80% !important;
        height: 50%;
    }

    .Button-BetType {
        color: white;
        background-color: green;
        border: none;
        width: 30%;
        height: 120% !important;
        border-radius: 10px;
    }

}
/* BetType */
.div-button-Betype {
    max-width: 100%;
    display: flex;
    justify-content: first baseline;
    align-items: center;
    flex-wrap: wrap;
}

/* Tengha */
.gray-checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #888;
}
