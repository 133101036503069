#transactionHistory th, #transactionHistory td {
    border: solid 1px #fff;
}
.transactionHistory-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.transactionHistory-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.trans-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    margin-left: 500px;
    /* text-decoration: underline; */
}

.transactionHistory-detail {
    width: 100%;
    padding: 30px 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.transactionHistory-btn-search {
    max-width: 120px;
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    background: #14A800;
}

.transactionHistory-select {
    max-width: 250px;
    width: 100%;
    height: 42px;
    padding: 0 12px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    background: #fff;
    cursor: pointer;
}

.transactionHistory-input {
    max-width: 250px;
    width: 100%;
    height: 42px;
    padding: 0 16px !important;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #1a1a1a;
    background: #fff;
}


.th-th, .td-th {
    border: 1px solid white; 
    padding: 8px;
    /* text-align: center;  */
}

.trans-IconBack {
    height: 30px;
    width: 30px;
}

@media (max-width: 768px) and (min-width: 70px) {

    .f9 {
        font-size: 9px !important;
    }

    .trans-IconBack {
       display: none;
    }

    .trans-title {
        margin-left: 20px;
    }

    .transactionHistory-detail {
        flex-wrap: wrap-reverse;
        padding: 0 0;
    }

    .inputTransH {
        max-width: 280px !important; 
    }
    
    .transactionHistory-select {
        max-width: 120px !important;
    }

    .transactionHistory-input {
        max-width: 120px !important;
        margin-right: 120px;
    }

    .transactionHistory-btn-search {
        max-width: 70px;
        height: 30px;
        font-size: 12Px;
        line-height: 24px;
       
    }
    
}