/* Games */
@media screen and (max-width: 768px) {
    .tabBar, .games {
        display: none !important;
    }
    .showRa .selectGame {
        text-align: center;
    }
    .d4-bet-amount input{
        margin-left: 1px !important;
    }
    .table-input-Shio-th {
        width: 140px;
    }
    .table-input-Shio{
        width: 60% !important;
        height: 25px !important;
        margin-left: 5px !important;
        padding: 0 !important;
    }
    .report-input-mobile{
        height: 32px !important;
        margin: 0 !important;
        width: 110px !important;
    }
    .report-select-mobile {
        margin-right: 10px !important;
    }
    .report-detail {
        margin: 20px 0;
    }
    button.dropdown-toggle.btn.btn-secondary{
        min-width: 50px !important;
        margin-right: 10px;
    }
    .dropdown-toggle::after {
        margin: 0 !important;
        float: inherit !important;
    }
    .showRa-report {
        display: flex !important;
    }
    .react-datepicker-popper {
        left: 50px !important;
    }
    #table-history th {
        padding: 5px 0;
    }
    .history-button{
        margin-right: 5px !important;
        text-align: left;
    }
    .history-list-button{
        width: 100% !important;
        height: 40px !important;
        font-size: 12px !important;
        padding: 5px 2px !important;
        margin: 0 5px !important;
    }
    .history-Img-Back {
        height: 32px !important;
    }
    .history-Title-Text {
        margin-left: 5% !important;
    }
    .d2-quickbet {
        height: 30px !important;
    }
    .table-select.d2-quickbet {
        padding-left: 0px !important;
    }
}
 /*PC*/
.result-select {
    max-width: 250px;
    height: 42px;
    padding: 0 12px;
    outline: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    background: #fff;
    cursor: pointer;
}
.button-submit {
    background-color: #54C830;
    border: none;
    border-radius: 10px;
    color: white;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
}
.d4-bet-amount input {
    width: 30% !important;
}
.table-4d .bet-input:disabled {
    background: #c7b7b7;
}
.table-4d .bet-input {
    border: 1px #fff solid;
    color: white;
    width: 100%;
    background: none;
}
.table-4d th {
    text-align: center;
    font-size: 18px;
}
.table-4d td input {
    width: 100%;
}

.d4-bet-amount {
    text-align: center;
}
.games .active {
    background: #FDD122;
}

.guide .modal-content {
    background-color: #2e3193;
}

table tr td, table tr th {
    background: inherit !important;
    color: inherit !important;
}

.games-wrapper {
    width: 100%;
    min-height: 100vh;
}

.games-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding-bottom: 40px;
}

.games-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.games-tab-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

/* ORIGINAL */
.table {
    width: 100%;
    margin: 0;
}

.tab-info {
    box-shadow: black 0px 20px 30px -10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2e3192;
    border-radius: 10px;
    text-align: center;
    padding-bottom: 80px;
    width: 800px;
}

table tr td {
    background: transparent;
}

.table-item {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;
}

.table-item > * {
    flex-grow: 1; /* Chia đều không gian cho tất cả các phần tử */
    margin-right: 10px; /* Tạo khoảng cách giữa các phần tử */
}

.table-group-btn {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.table-btn {
    max-width: 100px;
    width: 100%;
    height: 36px;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    background: #54C830;
}

.table-btn:hover {
    opacity: 0.8;
}

.table-item .number {
    flex-grow: 0; /* Không mở rộng phần tử đầu tiên */
    flex-basis: 40px; /* Độ dày mong muốn cho phần tử đầu tiên */
}

.table-input-pd {
    max-width: 350px;
    width: 100%;
    height: 42px;
    padding: 0 4px !important;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #1a1a1a;
    background: #fff;
}

.table-input-none {
    max-width: 350px;
    width: 100%;
    height: 36px;
    padding: 0 16px;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    background: #fff;
    color: #778090;
}

.table-item > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-item > h3 {
    flex: 0 0 auto; /* Không mở rộng và không co lại, sử dụng kích thước tự nhiên */
}

.table-item > span, .table-item > div {
    flex: 1; /* Mở rộng và co lại tùy theo không gian còn lại */
}

.table-input {
    max-width: 350px;
    width: 100%;
    height: 36px;
    padding: 0 16px;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #1a1a1a;
    background: #fff;
}

.table-select {
    max-width: 250px;
    width: 100%;
    height: 36px;
    padding: 0 12px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    background: #fff;
    cursor: pointer;
}

.table-thead {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
}

.table-display {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.tab-item {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #417487;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
}

.tab-body {
    width: 100%;
    padding: 16px;
}

.tab-text {
    margin: 0;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #fff !important;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-title {
    margin: 0;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff !important;
    background-color: transparent !important;
}

.tab-title-Total {
    display: flex;
    justify-content: end;
}

.games-tab-item {
    min-width: 160px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #2E3192;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
}

.games-tab-item:hover {
    background: #3f43a8;
}

.games-tab-item-active {
    min-width: 160px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    background: #FDD122;
}

.games-tab-select {
    display: none;
    width: 40% !important;
    border: none;
}

/* @media (max-width: 575px) {
    .games-tab-select {
        display: block;
        width: 100%;
        height: 42px;
        padding: 0 12px;
        border: none;
        outline: none;
        border-radius: 8px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #000;
        cursor: pointer;
    }
} */

/* @media (max-width: 375px) {
    .games-tab-item {
        min-width: 145px;
    }
} 
@media (max-width: 375px) {
    .games-tab-item-active {
        min-width: 145px;
    }
}
@media (max-width: 765px) {
    .tab-overlow {
        width: 100%;
        overflow: auto;
    }
}
@media (max-width: 765px) {
    .tab {
        width: 800px;
    }
}  */

.selectGameMobile {
    width: 100%;
    margin-left: -90px;
    border-radius: 5px;
    height: 35px;
    padding: 0 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='black' d='M0 1l4 4 4-4H0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 15px 15px;
}

.showRa {
    display: none;
}

/* Iphone 12 Pro Max RESPONSIVE*/
@media (max-width: 768px) and (min-width: 70px) {
    /* .games-tab-list {
        display: block;
    }
    .games-tab-item {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .font-size-15 {
        font-size: 15px;
    } */
    .tab-item {
        height: 50px;
    }

    .showRa {
        display: block;
    }

    .option-Home {
        width: 100% !important;
        border: none !important;
    }

    .games-tab-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }

    .games-tab-select {
        display: block;
        margin-bottom: 10px;
    }

    .games-tab-items {
        display: none;
    }

    .games-tab-item-active {
        display: block;
    }

    .anDi {
        display: none !important;
    }


    /* COMPONENT 4D */
    .tab-text-4D-BBC {
        margin-left: 0px !important;
        width: 15% !important;
        font-size: 12px;
    }

    .table-select-4D-BBC {
        margin-left: 30px !important;
        max-width: 35% !important;
        height: 30px;
        text-align: center !important;
        font-size: 12px;
        border-radius: 5px;
    }

    .tab-text-Col1-BBC,
    .tab-text-Col2-BBC {
        width: 30% !important;
    }

    .mgr17-BBC {
        margin-top: 2px !important;
    }

    .tab-text-BB-C {
        font-size: 12px !important;
        height: 30px !important;
        /*width: 55px !important;*/
    }

    .tab-text-Col2-BBC-3D {
        padding-bottom: 22% !important;
    }

    .table-display-BB-C-1-under {
        width: 50% !important;
    }

    .tab-text-2D-under {
        width: 60% !important;
        padding-top: 4% !important;
    }

    .hhgg {
        margin-left: -14% !important;
    }

    .tab-text-1000-CCB {
        position: absolute;
        margin-top: 0.75%;
        margin-left: 57%;
    }

    .table-display-BB-C-1,
    .table-display-BB-C-2,
    .table-display-BB-C-3 {
        display: flex;
        width: 100% !important;
        max-width: 100% !important;
    }

    .tab-text-Prize123-TotalBet {
        font-size: 15px !important;
    }

    .select-Number-432D {
        font-size: 12px;
        height: 30px !important;
        width: 50px !important;
    }

    .table-thead-432D {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        height: 0px !important;
    }

    .col01-432D {
        /*width: 10% !important;*/
    }

    .col03-432D {
        width: 180px !important;
        margin-left: 1% !important;
    }

    .col04-432D,
    .col05-432D {
        margin-left: 0 !important;
        width: 18% !important;
    }

    .col02-432D,
    .col04-432D,
    .col05-432D {
        padding-left: 7px;
    }

    .button-Autobet01 {
        transform: translateX(-50px); /* Di chuyển sang trái 50px */
    }

    .table-item-Prize123 {
        border-bottom: 1px solid #fff !important;
    }
    .table-4d th, .table-4d td input{
        font-size: 12px;
        text-align: center;
    }
    .tengah-nomor{
        width: 20px;
    }
    .table-4d .bet-input, .table-4d .d4-bet-amount, .table-4d .table-input {
        width: 40px;
        padding-left: 2px;
        padding-right: 2px;
        height: 32px;
    }
    .table-4d {
        /*display: table-caption;*/
    }
    .table-4d .bayar {
        min-width: 60px;
        padding-left: 1px;
        padding-right: 1px;
    }
    .table-4d th, .table-4d td {
        text-align: center;
        padding-left: 1px;
        padding-right: 1px;
    }
    .table-input-Prize123, .table-4d .table-input-none {
        height: 30px;
    }

    .button-Autobet {
        height: 30px;
        font-size: 10px !important;
        padding: 0 !important;
    }

    .button-Autobet02 {
        margin-left: 5% !important;
        box-sizing: border-box;
    }

    .button-Autobet03 {
        margin-left: 5% !important;
        margin-right: -1% !important;
    }

    .table-display-4D-Prize {
        height: 35px;
    }

    .table-input-none-432D {
        max-width: 60px !important;
        font-size: 12px;
    }

    .table-input-none-432D {
        height: 30px !important;
        font-size: 12px;
    }

    .div-Button-Auto-432D {
        margin-left: -30% !important;
    }

    .tab {
        width: 100% !important;
    }

    .table-input-4D {
        max-width: 40% !important;
    }

    .table-thead-4D {
        font-size: 15px !important;
    }

    .table-thead-4D-01 {
        margin-left: -20px !important;
    }

    .table-thead-4D-01,
    .table-thead-4D-02 {
        font-size: 10px;
    }

    .table-thead-4D-03 {
        font-size: 10px;
        margin-right: 90px;
    }

    .table-4D {
        width: 105% !important;
        margin-left: -2.5% !important;
    }

    .table-select-4D-Select-1 {
        height: 25px;
        font-size: 8px;
        padding-left: 2px !important;
        margin-left: -10px !important;
        width: 70px !important;
    }

    .table-select-4D-Select-2 {
        height: 25px;
        font-size: 8px;
        margin-left: -10px !important;
    }

    .table-input-4D {
        width: 100px !important;
        height: 25px !important;
        font-size: 8px !important;
        text-align: center;
    }

    .button-Submit-4D {
        height: 35px;
        font-size: 15px;
        width: 95px !important;
    }

    .tab-item-4D {
        font-size: 15px !important;
        height: 50px;
    }

    .table-item-4D {
        height: 60px !important;
    }

    .table-thead-4D {
        height: 50px;
    }

    .notification4D {
        margin-top: 20%;
        width: 90% !important;
        height: 100% !important;
        font-size: 10px !important;
    }

    .title-Noti {
        border-bottom: 1px solid white;
        width: 135px;
        display: flex;
        margin: auto;
    }

    .fourd-betting-table {
        border: none !important;
        font-size: 20px !important;
    }

    .table-input-2D {
        font-size: 10px;
        height: 25px;
    }

    .table-thead-2DDepen {
        padding: 0 0 10px 0 !important;
    }

    .table-thaed-2D-th01 {
        width: 10% !important;
        margin-left: 0.1% !important;
    }

    .table-thaed-2D-th02 {
        width: 25% !important;
    }

    .table-thaed-2D-th03 {
        padding-left: 2% px !important;
        margin-left: 0% !important;
    }

    .table-thaed-2D-th04,
    .table-thaed-2D-th05 {
        width: 15% !important;
    }

    .depan2D {
        padding: 0px !important;
        align-items: center;
    }

    .table-input-2D-01,
    .table-input-2Ddepen,
    .select-4D-QuickBet {
        height: 30px;
        padding: 0 !important;
    }

    .table-input-2D-01 {
        min-width: 30px !important;
    }

    .select-4D-QuickBet {
        border-radius: 5px;
    }

    /* D4 COMPONENT - QUICK 2D  COMPONENT RESPONSIVE*/
    /* BET FULL 4D*/
    .table-display-BetFull {
        display: flex;
        flex-wrap: wrap;
    }

    .table-display-BetFull > .table-display {
        flex: 1 1 calc(33.33% - 10px);
    }

    .table-display-BetFull > .table-display:last-child {
        margin-right: 0;
    }

    .table-input-BetFull {
        height: 30px;
        border-radius: 5px;
    }

    .qB-1000 {
        margin-left: 0% !important;
    }

    .table-btn {
        width: 20%;
        height: 15%;
        border-radius: 5px !important;
    }

    .font-12 {
        font-size: 12px !important;
    }

    .font-10 {
        font-size: 10px !important;
    }

    .table-btn-BTN {
        font-size: 10px;
        height: 30px;
        width: 80px;
        border-radius: 5px;
        padding: 0 !important;
    }

    /* TENGAH COMPONENT */
    .table-thead-Tengah {
        padding: 5px !important;
    }

    .table-item-TT {
        padding: 0 !important;
    }

    .col-02-Tengah {
        width: 50%;
        padding-right: 8%;
    }

    .table-input-Tengah {
        margin-left: 0.5% !important;
        width: 70% !important;
        height: 25px;
        border-radius: 5px;
    }

    .numTengah {
        margin-left: 1% !important;
    }

    /* DASAR COMPONENT RESPONSIVE*/
    .table-thead-DS {
        padding: 5px 0 !important;
    }

    .table-input-Dasar {
        height: 25px;
        border-radius: 5px;
        width: 120%;
    }

    .col01-D {
        width: 20% !important;
    }

    .col02-D,
    .col03-D {
        margin-left: 3%;
        width: 27% !important;
    }

    .col04-D {
        margin-left: 2%;
    }

    .col-01-DS {
        width: 16% !important;
    }

    .col-02-DS,
    .col-03-DS {
        margin-left: 1% !important;
        width: 30% !important;
    }

    .coltd04-D {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    }

    .table-item-Dasar {
        padding-top: 5px !important;
        padding-bottom: 0px !important;
    }

    .x-Dasar {
        padding: 0 !important;
        margin-left: 5% !important;
    }

    /* SILANG COMPONENT RESPONSIVE */
    .table-item-Silang {
        padding: 3px 0 !important;
    }

    .tab-title-SL {
        padding: 25px 0 5px !important;
    }

    .table-input-Silang {
        margin-left: -20% !important;
        height: 25px;
        border-radius: 5px;
        width: 70% !important;
    }

    .table-input-none-SL {
        border-radius: 5px;
        height: 25px;
        padding: 0 !important;
        font-size: 10px !important;
    }

    .tab-text-Silang-X {
        margin-left: -15% !important;
    }

    /*5050 COMPONENT RESPONSIVE*/
    .table-thead-5050 {
        padding: 2% 0 !important;
    }

    .table-input-none-5050 {
        padding: 0 !important;
        height: 25px;
        border-radius: 2px;
    }

    .tab-text-5050-04 {
        padding: 0 !important;
    }

    .table-input-5050 {
        margin-left: 5%;
        margin-right: 10% !important;
        border-radius: 2px;
        width: 45% !important;
        padding: 0 !important;
        height: 25px;
    }

    /* KOMBINASI COMPONENT RESPONSIVE*/
    .tab-overlow-KB {
        width: 100% !important;
    }

    .row01-Kembang {
        padding: 9px 0 9px 0 !important;
    }

    .table-thead-col04 {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .table-select-KB-02,
    .table-select-KB-03 {
        font-size: 12px !important;
        margin-right: 0px !important;
    }

    .table-item-col-KB-01 {
        font-size: 12px !important;
        margin-right: 0px !important;
    }

    .table-input-none-KBS {
        font-size: 10px !important;
    }

    .table-input-none-KB-Auto {
        font-size: 10px !important;
        padding: 0 !important;
        height: 25px !important;
        max-width: 50% !important;

    }

    .table-thead-Kom {
        padding: 0 0 0 5px;
    }

    .table-select-KB-02,
    .table-select-KB-03,
    .table-select-KB-04 {
        height: 25px;
    }

    /* COLOK JUTU COMPONENT RESPONSIVE */
    .tab-overlow-CJ {
        width: 100% !important;
    }

    .table-item-CJ {
        padding: 7px 0 !important;
    }

    .col-CJ-04-01 {
        margin-left: -25% !important;
    }

    .tab-text-1000 {
        margin-left: -2% !important;
    }

    .table-input-CJ,
    .table-select-CJ {
        height: 25px;
        border-radius: 5px !important;
    }

    .table-input-CJ-2 {
        height: 25px !important;
        border-radius: 5px !important;
        padding: 0 !important;
        margin-left: 5% !important;
    }

    .table-display-CJ {
        width: 100% !important;
        max-width: 100% !important;
    }

    .tab-text-1000-CJ {
        margin-left: 5% !important;
    }

    .table-input-none-CJ {
        font-size: 10px !important;
        height: 25px !important;
        padding: 0 !important;
        width: 85px !important;
        max-width: 75px !important;
    }

    /* COLOK NAGA COMPONENT RESPONSIVE */
    .fourd-betting-table-CN {
        /* width: 200% !important; Không cho Betting Table rộng theo*/
    }

    .item01-CN {
        font-size: 12px;
    }

    .table-thead-CN {
        padding: 0 !important;
    }

    .item02-CN,
    .item03-CN,
    .item04-CN {
        width: 13%;
        max-height: 25px !important;
        padding-bottom: 5px !important;
    }

    .div-Tabakan-CN-01,
    .div-Tabakan-CN-02,
    .div-Tabakan-CN-03 {
        display: flex;
        flex-direction: column;
    }

    .div-col-Right-CN {
        font-size: 10px !important;
        margin-left: -20% !important;
    }

    .table-input-none-CN {
        margin-left: -5% !important;
        max-width: 90% !important;
        height: 25px;
        padding: 0 !important;
    }

    .col-CN-02-CN {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        padding: 0 !important;
    }

    .table-input-CN {
        padding: 0px !important;
        width: 190% !important;
        max-width: 100% !important;
        height: 25px !important;
        border-radius: 5px !important;
    }

    .tab-text-1000-CN {
        font-size: 12px;
        width: 120px;
        margin-top: -7% !important;
    }

    .table-item-CN {
        padding: 10px !important;
        height: 60px !important;
        font-size: 12px !important;
        margin: 0 !important;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .item01-CN {
        width: 3% !important;
    }

    .item02-CN {
        max-width: 15% !important;
        margin-left: 2%;
        height: 32px !important;
    }

    /* MACAU COMPONENT RESPONSIVE */
    .item02-M {
        margin-left: 1% !important;
    }

    .item02-M,
    .item03-M {
        max-width: 15% !important;
        height: 25px !important;
    }

    .tab-text-1000-M {
        font-size: 12px;
        width: 120px;
    }

    .table-item-M {
        padding: 0 10 0 10 !important;
        font-size: 12px !important;
        margin: 0 !important;
        text-align: center;
        justify-content: center;
        align-items: center;

    }

    /* COLOK BEBAS COMPONENT RESPONSIVE*/
    .tab-text-taru-CB {
        margin-left: -15% !important;
    }

    .table-input-none-CB {
        position: relative;
        max-width: 60px !important;
        width: 60px !important;
        height: 25px !important;
        font-size: 10px !important;
        text-align: center;
        padding: 0 !important;
        margin-left: -5% !important;
    }

    .tab-text-CB-1000 {
        font-size: 12px;
        margin-left: -5% !important;
    }

    .table-input-CB {
        height: 25px !important;
        margin-left: 5% !important;
        box-sizing: content-box;
        border-radius: 5px !important;
    }

    .tab-text-Tebakan-CB {
        max-width: 22% !important;
        padding: 0 !important;
        font-size: 12px;
    }

    .cb-Bebas-100 {
        margin-left: -5% !important;
    }

    .table-item-CB {
        padding: 1.5%;
    }

    .tab-text-Number-CB {
        width: 10% !important;
        font-size: 12px;
        margin-left: 1% !important;
        padding: 0 !important;
    }

    .col-Number-CB {
        margin-left: 5% !important;
        padding: 0 !important;
        font-size: 12px;
        width: 70px !important;
        max-width: 70px !important;
    }

    .col-Tebakan-CB {
        font-size: 12px;
        margin-left: 15% !important;
    }

    .col-right-CB {
        margin-left: -5%;
        padding-left: -12% !important;
        display: flex;
        align-items: center;
    }

    .tab-text-CB {
        font-size: 12px !important;
    }

    /* SHIO COMPONENT RESPONSIVE */
    .table-item-Shio {
        padding: 2px !important;
    }

    .tab-text-Shio02 {
        margin-left: 70% !important;
    }

    .table-display-Shio {
        flex-direction: none;
    }

    .table-thead-Shio {
        padding: 0 !important;
    }

    .div-cl2-Shio {
        width: 80% !important;
        max-width: 60% !important;
        display: flex !important;
        text-align: center;
        align-items: center;
    }

    .text-Shio-Taru {
        margin-left: -50% !important;
    }

    .table-input-none-Shio {
        height: 25px !important;
        width: 50px !important;
        margin-left: 5px !important;
        padding: 0 !important;
    }

    .x000Shio {
        margin-left: 5px !important;
    }

    .shio-Bayar {
        margin-left: 12% !important;
        font-size: 10px !important;
    }

    /* KEMBANG COMPONENT RESPONSIVE */
    .taruhan-KB {
        padding-left: 35% !important;
    }

    .table-input-none-kb {
        height: 25px;
        padding: 0 !important;
    }

    .table-item-KB {
        padding: 0 !important;
    }

    .table-input-KB {
        height: 25px;
        width: 60% !important;
        margin-left: 5% !important;
    }

    .tab-text-1000-KB {
        margin-left: -5% !important;
    }


}

/* TENGAH COMPONENT */
.col-02-Tengah {
    width: 50%;
    padding-right: 8%;
}

.col-01-Tengah {
    width: 50%;
    padding-right: 12%;
}

.col-02-Tengah-down {
    display: flex;
    width: 50%;
    padding: 0;
}

.table-input-Tengah,
.numTengah {
    margin-left: -30%;
}

/* DASAR COMPONENT*/
.table-item-Dasar {
    justify-content: space-around;
}

.tabtab-styled-box-Dasar {
    width: 100%;
    padding: 20px 16px;
}

.col01-D {
    width: 8%;
    margin-left: -2%;
}

.col02-D,
.col03-D {
    width: 16%;
}

.col04-D {
    width: 50%;
}

.x-Dasar {
    margin-left: -18%;
}

.col-01-DS {
    width: 5%;
}

.col-02-DS,
.col-03-DS {
    width: 14%;
    margin-left: 2%;
}

.coltd04-D {
    width: 50%;
}

/* SILANG COMPONENT*/

.table-thead-01 {
    width: 8%;
}

.table-thead-02,
.table-thead-03 {
    width: 16%;
    margin-left: -5%;
}

.table-thead-04 {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding-left: 5%;
}

.table-item-Silang {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;
    justify-content: space-between;
}

.table-display-Silang {
    width: 50%;
    display: flex;
    align-items: center;
}

.table-input-Silang {
    width: 50%;
    margin-left: -35%;
}

.tab-text-Silang01 {
    width: 8%;
}

.tab-text-Silang02,
.tab-text-Silang03 {
    width: 16%;
    margin-left: -5%;
}

.tab-title-SL {
    padding: 50px 0 30px;
}

.table-input-none-SL {
    width: 25%;
    text-align: center;
}

.tab-text-Silang-X {
    margin-left: -35%;
    display: flex;
}

/* KEMBANG COMPONENT*/
.table-thead-th-01 {
    width: 6%;
    margin-left: -1%;
}

.table-thead-th-02 {
    width: 12%;
    margin-left: 2%;
}

.table-thead-th-03 {
    width: 12%;
    margin-left: 3%;
}

.table-thead-th-04 {
    width: 45%;
    margin-left: 10%;
}

.taruhan-KB {
    margin-left: -35%;
}

.table-input-none-kb {
    text-align: center;
    width: 35%;
}

.table-item-KB-01 {
    width: 1%;
}

.table-item-KB-02,
.table-item-KB-03 {
    width: 8%;
    margin-left: 1%;
}

.table-item-KB-03 {
    padding-left: 1.5%;
}

.table-item-KB-04 {
    width: 40%;
    margin-left: 10%;
}

.table-input-KB {
    width: 50%;
    margin-left: -30%;
}

.tab-text-1000-KB {
    margin-left: -30%;
}

/* COLOK NAGA COMPONENT */
.table-input-CN {
    padding: 0 !important;
}

.col-CN-01-1 {
    width: 6% !important;
    margin-left: -2%;
}

.div-Tabakan-CN-01 {
    margin-left: -3%;
    padding-left: 2%;
}

.div-Tabakan-CN-01,
.div-Tabakan-CN-02,
.div-Tabakan-CN-03 {
    width: 12% !important;
}

.col-CN-num {
    margin-left: 5%;
}

.col-CN-02 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 40% !important;
    position: relative;
    padding-left: 8%;
}

.table-item-CN,
.table-item-M {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;
}

/* .table-item-M {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;
} */
.col-CN-02-CN {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 40% !important;
    position: relative;
    padding-left: 50px;
}

.item01-CN {
    margin-left: 1%;
    width: 5%;
}

.item02-CN {
    max-width: 12%;
    margin-left: 2%;
}

.item03-CN,
.item04-CN {
    max-width: 12%;
    margin-left: 3%;
}

.div-col-Right-CN {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.table-input-none-CN {
    max-width: 30%;
    text-align: center;
}

.vrr {
    margin: 0;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #fff !important;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* MACAU COMPONENT */
.table-thead-M {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.col-M-01-1 {
    width: 20%;
    margin-left: -12%;

}

.div-Tabakan-M-01 {
    width: 30%;
    margin-left: 2%;
}

.div-Tabakan-M-02 {
    width: 30%;
    margin-left: 2%;
}

.col-M-02 {
    width: 40%;
    padding-left: 5%;
}

.item01-M {
    width: 8%;
}

.item02-M {
    width: 13%;
    margin-left: 5%;
}

.item03-M {
    margin-left: 5%;
    width: 13%;
}

.col-CN-02-M {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 40% !important;
    position: relative;
    padding-left: 10%;
}


/* KOMBINASI COMPONENT*/
.table-input-none-KB-Auto {
    text-align: center;
}

.table-thead-col01 {
    width: 7%;
}

.table-thead-col02 {
    padding-right: 4%;
}

.table-thead-col03 {
    display: block;
}

.table-thead-col02,
.table-thead-col03 {
    margin-left: 1%;
    width: 35%;
}

.table-thead-col04 {
    width: 28%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-left: 1%;
    padding-right: 1%;
}

.auto-bet-KBM {
    width: 40% !important;
}

.table-item-col-KB-01 {
    max-width: 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-select-KB-02,
.table-select-KB-03 {
    width: 32% !important;
    max-width: 32% !important;
}

.table-select-KB-04 {
    max-width: 120% !important;
    width: 120% !important;
    justify-content: center;
}

.table-input-none-KBS {
    width: 100%;
    text-align: center;
    height: 42px !important;
    padding: 0 !important;
}

/* COLOK BEBAS COMPONENT */
.table-input-none-CB {
    max-width: 20%;
    width: 20%;
}

.col-left-CB {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.col-Number-CB {
    width: 12%;
}

.col-Tebakan-CB {
    margin-right: 50%;
    width: 25%;
    padding-left: 5%;
}

.col-right-CB {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 50%;
    padding-left: 7%;
}

.tab-text-Number-CB {
    max-width: 8%;
}

.tab-text-Tebakan-CB {
    max-width: 25%;
    width: 30%;
    padding-right: 7%;
}

.table-input-CB {
    max-width: 30%;
    margin-left: 20%;
}

.cb-Bebas-100 {
    align-items: center;
    align-content: center;
    margin: 0;
}

/* COLOK JITU COMPONENT*/
.col-CJ-01 {
    width: 6%;
    margin-left: -7%;
}

.col-CJ-02 {
    padding-right: 4%;
}

.col-CJ-02,
.col-CJ-03 {
    width: 12%;
    margin-left: -12%;
}

.col-CJ-04 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 40% !important;
    position: relative;
    padding-left: 5%;
}

.table-input-none-CJ {
    max-width: 30%;
    text-align: center;
}

.table-item {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;
}

.table-item-CJ {
    padding: 12px 0;
    display: flex;
    justify-content: space-around;
    gap: 12px;
    border-bottom: 2px solid #fff;
}

.tab-text-1000 {
    margin-left: 37%;
}

.tab-text-CJ {
    width: 6%;
    margin-left: -3%;
}

.table-input-CJ {
    width: 14%;
    margin-left: -7%;
}

.table-select-CJ {
    margin-left: -7%;
    width: 80%;
    max-width: 80%;
}

.table-display-CJ {
    width: 46% !important;
}

.table-select-CJ {
    width: 20%;
}

.table-input-CJ-2 {
    margin-left: -15%;
}

.tab-text-1000-CJ {
    margin-left: -20%;
}

/* SHIO COMPONENT */
.tab-styled-box-Shio {
    width: 100%;
    padding-left: 2%;
}

.table-item-Shio {
    display: flex;
    justify-content: space-around;
}

.table-Shio-01 {
    display: flex;
    width: 50%;
    border: none !important;
}

.table-Shio-02 {
    display: flex;
    width: 50%;
    position: relative;
    left: 2%;
}

.number-Order-Shio {
    width: 12% !important;
    padding-right: 2%;
}

.shio-Number {
    width: 12%;
    border: none;
}

.table-input-none-Shio {
    width: 35%;
    text-align: center;
}

.shio-Bayar {
    margin-left: 20%;
    border: none !important;
}

.tab-text-Shio02 {
    margin-left: -30%;
}

.item-Bayar {
    position: relative;
    right: 5%;
}

/* 50 - 50 COMPONENT*/
.table-thead-5050 {
    justify-content: stretch;
}

.table-5050 {
    border-bottom: 2px solid white;
}

.col-5050-01,
.col-5050-02,
.col-5050-03 {
    width: 12%;
    margin-left: 2%;
}

.col-5050-04 {
    margin-left: 17%;
    display: flex;
    align-items: center;
}

.table-input-none-5050 {
    width: 35%;
    margin-left: 2%;
    margin-right: 2%;
}

.table-input-5050 {
    width: 35%;
    margin-right: 5px;
}

.tab-text-5050-02 {
    width: 12%;
    margin-left: 15px;
}

.tab-text-5050-01,
.tab-text-5050-03 {
    width: 16%;
}

.tab-text-5050-04 {
    padding: 10px;
}

.table-input-none-5050,
.col-5050-04,
.tab-text-5050-02,
.tab-text-5050-01,
.tab-text-5050-03,
.tr-5050-02-01,
.tr-5050-02-02,
.tab-text-5050-04 {
    text-align: center;
}

.cl-1000-5050 {
    margin-left: -25%;
}

/* 4D COMPONENT*/
.tab-D4 {
    margin-bottom: 0 !important;
}

.notification4D {
    position: absolute;
    background-color: #2e3192;
    width: 50%;
    height: 130%;
    text-align: center;
    border-radius: 20px;
    z-index: 1;
}

.title-Noti {
    border-bottom: 1px solid white;
    width: 135px;
    display: flex;
    margin: auto;
}

.buttonChose {
    cursor: pointer;
    padding: 10px 10px;
}

.fourd-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding-bottom: 50px;
}

.fourd-betting-table {
    margin-top: 35px;
    width: 100%;
    border: 5px solid #475EA5;
    background: transparent;
    border-radius: 6px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}

.tab-overlow {
    width: 100%;
}

.tab {
    width: 100%;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #417487;
}

.tab-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tab-styled-box {
    width: 100%;
    padding: 30px 0;
}

/* 4D - BB COMPURAN - COMPONENT */
.mgr17-BBC {
    margin-top: 17px;
}

.table-display-BB-C {
    display: flex;
}

.table-display-BB-C-1-under {
    display: flex;
    width: 280px;
}

.table-display-BB-C-1,
.table-display-BB-C-2,
.table-display-BB-C-3 {
    display: flex;
    width: 280px;
}

.tab-text-Col1-BBC,
.tab-text-Col2-BBC {
    width: 100px;
}

.tab-text-Col2-BBC-3D {
    padding-bottom: 7%;
}

.tab-text-BB-C {
    width: 60%;
    border-radius: 5px;
}

.tab-text-4D-BBC {
    margin-left: 40px;
    width: 140px;
}

.table-select-4D-BBC {
    margin-left: 20px;
    max-width: 32%;
}

.tab-text-1000-CCB {
    padding-bottom: 2%;
}

/* 4D - QIUCK BET - COMPONENT */
.content-BetFull {
    width: 100%;
    height: 300px;
    background-color: #fff;
}

.table-input-BetFull {
    max-width: 50% !important;
}

.qB-1000 {
    margin-left: -10%;
}

/* 4D -4D3D2D - COMPONENT */
.tab-body-4D-QuickBet,
.tab-body-4D-432D {
    width: 100%;
    padding: 16px;
}

.select-4D-QuickBet {
    max-width: 78%;
}

.table-display-Prize123 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.col01-432D {
    width: 7%;
    margin-left: -3%;
}

.col02-432D {
    width: 15%;
}

.col03-432D {
    width: 20%;
    margin-left: 7%;
}

.col04-432D {
    width: 10%;
    margin-left: 15%;
}

.col05-432D {
    width: 10%;
}

.number-432D {
    width: 7%;
}

.table-item-432D {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;

}

.tab-text-432D {
    margin: 0;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: #fff !important;
    background-color: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-text-Prize123-TotalBet {
    justify-content: flex-end;
}

.table-display-Prize123 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.table-input-none-432D {
    width: 18% !important;
    background-color: transparent;
    border: 1px solid white;
}

.select-Number-432D {
    max-width: 120px;
    width: 70px;
    height: 38px;
    border-radius: 10px;
    border: none;
}

.table-display-4D-Prize {
    justify-content: center;
    margin-left: 35%;
}

.button-Autobet {
    /*width: 40% !important;*/
    max-width: 200px !important;
    margin: 0 1%;
    background-color: white;
    text-align: center;
}

.button-Autobet01 {
    margin-left: 34% !important;
    display: flex;
}

.cb-4D-432D {
    margin-left: 24% !important;
}

.div-Button-Auto-432D {
    margin-left: -2.5%;
    padding-left: 50% !important;
}

/* 4D - QUICK 2D - COMPONENT*/
.tab-body-4D {
    width: 100%;
    padding: 16px;
    position: relative !important;
}

.button-Close-Info-4D {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.table-item-4D {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    gap: 12px;
    border-bottom: 2px solid #fff;
    align-items: center !important;
    padding-left: 5%;
}

.table-item-4D > * {
    flex-grow: 1;
    margin-right: 10px;
}

.number-4D {
    margin-right: 20px;
    display: inline-block;
    text-align: center;
}

.table-select-4D-Select-1 {
    max-width: 25%;
}

.table-select-4D-Select-2 {
    max-width: 20%;
    margin-left: 5%;
}

.table-input-4D {
    flex: 2;
    margin-right: 0;
    max-width: 50%;
    height: 50px;
}

.tebok-container-4D {
    display: flex;
    justify-content: center;
    width: 1vh;
}

.div-Autobet-4D {
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    align-items: center;
}

.button01-4D {
    height: 42px;
    padding: 0 12px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    background: #fff;
    cursor: pointer;
    margin-top: 5px;
}

.button-Submit-4D {
    background-color: #54C830;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
}

.button-Kirim-4D {
    background-color: #54C830;
}

.button-Hapus-4D {
    background-color: #D0453B;
}

.button-Kirim-4D,
.button-Hapus-4D {
    margin: 10px 10px;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    color: white;
}

/* 2D DEPAN COMPONENT */
.table-input-2D {
    width: 50% !important;
    margin-left: 15%;
    text-align: center;
}

.bg-2D {
    background-color: transparent !important;
    width: 100% !important;
}

.table-thaed-2D-th01 {
    width: 5%;
    margin-left: -2%;
}

.tab-text-2D-numb {
    margin-left: 2%;
    padding: 15px 15px;
}

.table-input-Check01,
.table-input-Check02 {
    width: 25px !important;
}

.table-thaed-2D-th03 {
    padding-left: 2%;
    margin-right: 5% !important;
    display: flex;
    flex-direction: column;
}

.table-thaed-2D-th04 {
    margin-left: -5%;
}

.table-thaed-2D-th03,
.table-thaed-2D-th04,
.table-thaed-2D-th05 {
    width: 20%;
}

/* POPUP D4 COMPONENT */
.classpoup {
    width: 100%;
    text-align: center;
    /* align-items: center; */
}

.button-Popup-Red,
.button-Popup {
    background-color: #54C830;
    margin: 10px 10px;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
}

.com-Popup {
    background-color: white;
    color: black;
    width: 60%;
    margin: auto;
    border-radius: 10px;
}

.height-Popup {
    height: 45px !important;
}

.button-Popup-Red {
    background-color: red !important;
}


/* Disabled Input 4D, 3D, 2D - Component 4D */
.table-input-none-432D:disabled {
    background-color: #c7b7b7;
    color: linen;
    opacity: 1;
}

.table-input-none-Number:disabled {
    background-color: #c7b7b7;
    color: linen;
    opacity: 1;
}

.not-disabled {
    background-color: #c7b7b7;
}




