@font-face {
  font-family: ubuntu;
  src: url('./assets/font/Ubuntu-Regular.ttf');
}
body {
  margin: 0;
  /*font-family: ubuntu !important;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  background: #000C37 !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: Arial, sans-serif !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}