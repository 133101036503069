/* Layout */
.app-container {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
}

/* header */

.wrapper-header {
    width: 100%;
    height: 72px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    background-color: rgba(0,0,0,0.3);
    
}

.container-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
}

@media (max-width: 800px) {
    .container-header {
        /* flex-direction: row-reverse; */
        gap: 12px;
    }
}

.styled-header-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

@media (max-width: 800px) {
    .styled-header-group {
        flex-direction: row-reverse;
        align-items: center;
        gap: 60px;
    }
}

.styled-header-item-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

@media (max-width: 800px) {
    .styled-header-item-group {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
}

.styled-header-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

@media (max-width: 1000px) {
    .styled-header-item {
        gap: 6px;
    }
}

.styled-header-img {
    width: 25px;
    height: 25px;
}

@media (max-width: 768px) {
    .styled-header-img {
        width: 18px;
        height: 18px;
    }
}

.styled-text-header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    /* text-transform: uppercase; */
    color: #fff;
}

.styled-text-header-none {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.reportIcon,
.historyTransactionIcon {
        /*height: 24px !important;*/
        /*width: 24px !important;*/
        margin-right: 30px;
        color: white;
    }

@media (max-width: 768px) and (min-width: 70px) {


 
    
    .wrapper-header {
        width: 100%;
        height: 72px;
        padding: 0 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent !important;
        align-items: center;
        gap: 32px;
    }
    
    .menu-mobile-list {
        width: 75% !important;
        margin-left: 29%;
        height: 850px !important;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 20px;
        align-items: center;
        background-color: rgba(0,0,0,0.3);
        /* background-color: pink; */
    }

    .menu-mobile-btn-logout {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
        margin-top: 140% !important;
        height: 48px;
        border: none;
        border-radius: 12px;
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        background-color: transparent !important;
    }
    /*.menu-mobile-list-Img {*/
    /*    width: 45px !important;*/
    /*    height: 45px !important;*/
    /*}*/
    .menu-mobile-list-Text {
        margin-left: 5%;
        font-size: 25px;
    }

   
}


.menu-mobile-list-Img {
    width: 24px;
    height: 24px;
}



@media (max-width: 1000px) {
    .styled-text-header-none {
        display: none;
    }
}

@media (max-width: 1000px) {
    .styled-text-header {
        font-size: 10px;
    }
}

@media (max-width: 768px) {
    .styled-header-item-none {
        display: none;
    }
}

/* Menu mobile */
.menu-mobile {
    z-index: 99;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    display: none;
    top: 0;
    right: 0;
    padding: 0 16px;
    background-color: #000c37;
    color: #000;
}

.styled-header-bars {
    display: none;
}

@media (max-width: 768px) {
    .menu-mobile {
        display: block;
        width: 100%;
    }

    .styled-header-bars {
        display: flex;
    }
}

.styled-menu-mobile {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.menu-mobile-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* .menu-mobile-btn-logout {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 12px;
    color: #fff;
    background: #2E3192;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
} */

.menu-mobile-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 40px 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.no-scroll {
    overflow: hidden;
}


/* tabBar */

.tabBar {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-right: 16px;
    padding-left: 16px;
}

.app-tabbar-item {
    width: 100%;
    height: 42px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 5px solid #FDD122;
    background: #fff;
    color: #000;
}

.app-tabBar-text {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #081E60;
    margin: 0;
    display: flex;
    align-items: center;
}


/* Footer */
.styled-footer {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-top: 1px solid #fff */
}

.styled-btn-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
}

.btn-header {
    width: auto;
    height: 34px;
    padding: 0 12px;
    border: none;
    border-radius: 12px;
    color: #fff;
    background: #2E3192;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.btn-header-active {
    width: auto;
    height: 34px;
    padding: 0 12px;
    border: none;
    border-radius: 12px;
    color: #000;
    background: #FDD122;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .styled-btn-group {
        display: none;
    }
}