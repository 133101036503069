.status {
    display: block;
}
.status:first-letter {
    text-transform: uppercase;
}
button.dropdown-toggle.btn.btn-secondary {
    background: #fff;
    color: #000;
    padding-right: 5px;
    min-width: 150px;
    border-radius: 8px;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0.155em;
    content: "";
    border-top: 0.5em solid;
    border-right: 0.5em solid transparent;
    border-bottom: 0;
    border-left: 0.5em solid transparent;
    float: right;
    margin-top: 7px;
}
#report th, #report td {
    border: solid 1px #fff;
}
.report-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.report-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.report-title {
    font-size: 25px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    text-decoration: underline;
    text-decoration: none;
}

.bettingRe-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    margin-left: 500px;
    /* text-decoration: underline; */
}

.bettingRe-IconBack {
    height: 30px;
    width: 30px;
}

.report-detail {
    width: 100%;
    padding: 30px 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.report-btn-search {
    max-width: 120px;
    width: 100%;
    height: 42px;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    background: #14A800;
}

.report-select {
    max-width: 250px;
    width: 100%;
    height: 42px;
    padding: 0 12px;
    border: none;
    outline: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    background: #fff;
    cursor: pointer;
}

.report-input {
    max-width: 250px;
    width: 100%;
    height: 42px;
    padding: 0 16px !important;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #1a1a1a;
    background: #fff;
}

.selectGameBettingRp {
    height: 42px !important;
    max-width: 100% !important;
}

@media (max-width: 768px) and (min-width: 70px) {

    .f9 {
        font-size: 9px !important;
    }

    .bettingRe-IconBack {
        display: none;
    }

    .selectGameBettingRp {
        height: 32px !important;
        width: 140px !important;

    }
    
    .bettingRe-title {
       display: flex;
       margin: auto;
    }

    .inputBettingRe {
        width: 70% !important;
    }
    
    .report-detail {
        flex-wrap: wrap-reverse !important;
        padding: 0 0;
    }
 
    .report-select {
        max-width: 120px !important;
    }
    
    .report-input {
        max-width: 120px !important;
        margin-right: 90px;
    }

    .report-btn-search {
        width: 80px !important;
        font-size: 12px;
        height: 30px;
    }
    
    
}